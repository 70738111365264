const MockSeo = (i18n) => ({
  /* i18n-next-line except: ["es-AR"] */
  email: i18n.gettext('E-mail marketing'),
  /* i18n-next-line except: ["es-AR"] */
  positioning: i18n.gettext('Posicionamiento SEO'),
  /* i18n-next-line except: ["es-AR"] */
  database: i18n.gettext('Base de datos'),
});

export default MockSeo;
