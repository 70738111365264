import React from 'react';
import PropTypes from 'prop-types';

import contentTools from '../../../../wordings/tools';
import ToolCard from '../../../../components/ToolCard';
import Carousel from '../../../../components/Carousel';

const Tools = ({ i18n, siteId }) => {
  const Data = contentTools(i18n);
  const { title, description } = Data;

  const carouselSettings = {
    slidesToShow: 3,
    dots: false,
    arrows: false,
    draggable: false,
    fade: false,
    safeGapItems: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          fade: false,
          slidesToShow: 1.1,
          dots: true,
          infinite: false,
        },
      },
    ],
  };
  const namespace = 'ui-ms-landing-tools';

  return (
    <section className={namespace}>
      <div className={`${namespace}__container`}>
        <div className={`${namespace}__text-container`}>
          <h2 className={`${namespace}__title`}>{title}</h2>
          <p className={`${namespace}__description`}>{description}</p>
        </div>
        <div className={`${namespace}__carousel-container`}>
          <Carousel {...carouselSettings}>
            {Data.cards.map(({ id, text, animation }) => (
              <ToolCard
                key={id}
                text={text}
                animation={animation}
                siteId={siteId}
                i18n={i18n}
              />
            ))}
          </Carousel>
        </div>
      </div>
    </section>
  );
};

Tools.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
  siteId: PropTypes.string.isRequired,
};

export default Tools;
