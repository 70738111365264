import React from 'react';
import PropTypes from 'prop-types';

import { Image } from 'nordic/image';

import MockBioLibre from '../Mock/MockBioLibre';

const BioLibreAnimation = ({ siteId }) => {
  const textUrl = MockBioLibre();

  const phone = 'home/phone_animation.png';

  return (
    <div className="biolibre">
      <div className="biolibre__cont-image">
        <Image
          src={phone}
          className="biolibre__image biolibre__image-animation"
          alt=""
          tabIndex="-1"
        />
      </div>
      <div className="biolibre__cont-text biolibre__pill-animation">
        <p className="biolibre__text biolibre__typewriter-animation">
          {textUrl[siteId]}
        </p>
      </div>
    </div>
  );
};

BioLibreAnimation.propTypes = {
  siteId: PropTypes.string.isRequired,
};

export default BioLibreAnimation;
