import React from 'react';
import PropTypes from 'prop-types';

import BioLibreAnimation from './animations/BioLibre';
import SeoAnimation from './animations/Seo';
import SmAnimation from './animations/SocialMedia';
import Card from '../Card';

const ToolCard = ({ text, siteId, animation, i18n }) => {
  const Animate = {
    seo: <SeoAnimation i18n={i18n} />,
    biolibre: <BioLibreAnimation siteId={siteId} />,
    media: <SmAnimation />,
  };

  const namespace = 'ui-ms-landing-toolscard';

  return (
    <Card bgColor="white" className={namespace} borderRadius="md">
      <div className={`${namespace}__animation-cont`}>{Animate[animation]}</div>
      <div className={`${namespace}__cont-text`}>
        <h6 className={`${namespace}__title`}>{text}</h6>
      </div>
    </Card>
  );
};

ToolCard.propTypes = {
  text: PropTypes.string.isRequired,
  animation: PropTypes.oneOf(['biolibre', 'seo', 'media']).isRequired,
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
  siteId: PropTypes.string.isRequired,
};

export default ToolCard;
