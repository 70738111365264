const contentTools = (i18n) => ({
  /* i18n-next-line except: ["es-AR"] */
  title: i18n.gettext(
    'Todas las herramientas de marketing desde el primer momento',
  ),
  description: i18n.gettext(
    'Encuentra a potenciales compradores y acelera tus ventas con todas las herramientas que tienes incorporadas al crear tu tienda.',
  ),
  cards: [
    {
      id: 1,
      text: i18n.gettext('Convierte a tus seguidores en compradores'),
      animation: 'biolibre',
    },
    {
      id: 2,
      text: i18n.gettext(
        'Logra más visitas a tu tienda con soluciones de tráfico gratuito',
      ),
      animation: 'seo',
    },
    {
      id: 3,
      text: i18n.gettext(
        'Muestra tus productos con una estrategia de publicidad online',
      ),
      animation: 'media',
    },
  ],
});

export default contentTools;
