import React from 'react';
import PropTypes from 'prop-types';

import { Image } from 'nordic/image';
import classNames from 'classnames';

const CompanyAvatar = ({ avatar, text, colorText }) => {
  const TextClass = classNames('ui-ms-company-avatar__text', {
    [`ui-ms-company-avatar__text--text-${colorText}`]: colorText,
  });

  const LineClass = classNames('ui-ms-company-avatar-line', {
    [`ui-ms-company-avatar-line--${colorText}-line`]: colorText,
  });

  return (
    <>
      <div className={LineClass} />
      <div className="ui-ms-company-avatar">
        <Image
          src={avatar}
          alt={text}
          className="ui-ms-company-avatar__image"
        />
        <span className={TextClass}>{text}</span>
      </div>
    </>
  );
};

CompanyAvatar.propTypes = {
  avatar: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  colorText: PropTypes.oneOf(['black', 'white']),
};

export default CompanyAvatar;
