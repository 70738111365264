import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import Card from '../Card';
import CompanyAvatar from '../CompanyAvatar';
import StarCounter from './StarCounter';

const TestimonialCard = ({
  testimony,
  avatarImage,
  brand,
  color,
  bgColor,
  rating,
}) => {
  const TextClass = classNames('ui-ms-landing-testimonial-card__text', {
    [`ui-ms-landing-testimonial-card__text--text-${color}`]: color,
  });

  return (
    <Card bgColor={bgColor} className="ui-ms-landing-card">
      <div className="ui-ms-landing-testimonial-card">
        <StarCounter rating={rating} color={color} />
        <p className={TextClass}>{testimony}</p>
        <CompanyAvatar avatar={avatarImage} text={brand} colorText={color} />
      </div>
    </Card>
  );
};

TestimonialCard.propTypes = {
  testimony: PropTypes.string.isRequired,
  avatarImage: PropTypes.string.isRequired,
  brand: PropTypes.string,
  color: PropTypes.string.isRequired,
  bgColor: PropTypes.string.isRequired,
  rating: PropTypes.number.isRequired,
};

export default TestimonialCard;
