import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import TestimonialCard from '../../../../components/TestimonialCard';

const CasesDesktop = ({ cards, title }) => {
  const namespace = 'ui-ms-landing-cases';
  const [isVisible, setIsVisible] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const containerElement = containerRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      {
        threshold: 0.5,
        rootMargin: '0px',
      },
    );

    if (containerElement) {
      observer.observe(containerElement);
    }

    return () => {
      observer.unobserve(containerElement);
    };
  }, []);

  const visibleCLassName = classNames(namespace, {
    [`${namespace}--visible`]: isVisible,
  });

  return (
    <section className={visibleCLassName}>
      <h2 className={`${namespace}__title`}>{title}</h2>
      <div className={`${namespace}__card-container`} ref={containerRef}>
        {cards.map(
          ({ id, brand, testimony, avatarImage, color, bgColor, rating }) => (
            <TestimonialCard
              key={id}
              testimony={testimony}
              avatarImage={avatarImage}
              brand={brand}
              color={color}
              bgColor={bgColor}
              rating={rating}
            />
          ),
        )}
      </div>
    </section>
  );
};

CasesDesktop.propTypes = {
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      testimony: PropTypes.string.isRequired,
      avatarImage: PropTypes.string.isRequired,
      brand: PropTypes.string,
      color: PropTypes.string.isRequired,
      bgColor: PropTypes.string.isRequired,
      rating: PropTypes.number.isRequired,
    }),
  ).isRequired,
  title: PropTypes.string.isRequired,
};

export default CasesDesktop;
