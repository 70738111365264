const MockSocialMedia = () => ({
  logos: [
    {
      id: 0,
      image: 'home/toolcard_sm_01.png',
      alt: 'Instagram',
    },
    {
      id: 1,
      image: 'home/toolcard_sm_02.png',
      alt: 'Facebook',
    },
    {
      id: 2,
      image: 'home/toolcard_sm_03.png',
      alt: 'TikTok',
    },
    {
      id: 3,
      image: 'home/toolcard_sm_04.png',
      alt: 'Google',
    },
    {
      id: 4,
      image: 'home/toolcard_sm_05.png',
      alt: 'Mercado Ads',
    },
  ],
});

export default MockSocialMedia;
