import React from 'react';
import PropTypes from 'prop-types';

import MockSeo from '../Mock/mockSeo';

const SeoAnimation = ({ i18n }) => {
  const { positioning, email, database } = MockSeo(i18n);
  const namespace = 'seo-container';
  const classText = 'seo-container__cont-text';

  return (
    <div className={namespace}>
      <div className={`${classText} ${namespace}__primary-animation`}>
        {positioning}
      </div>
      <div className={`${classText} ${namespace}__secondary-animation`}>
        {email}
      </div>
      <div className={`${classText} ${namespace}__tertiary-animation`}>
        {database}
      </div>
    </div>
  );
};

SeoAnimation.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
};

export default SeoAnimation;
